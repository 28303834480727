<template functional>
  <svg
    :class="props.classes"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.11016 3.2C5.35872 2.50325 6.01903 2 6.8 2C7.7924 2 8.6 2.8076 8.6 3.8C8.6 4.7924 7.7924 5.6 6.8 5.6C6.01903 5.6 5.35872 5.09675 5.11016 4.4H2V3.2H5.11016ZM9.8 4.4V3.2H14V4.4H9.8ZM9.8 6.2C9.01903 6.2 8.35872 6.70325 8.11016 7.4H2V8.6H8.11016C8.35872 9.29675 9.01903 9.8 9.8 9.8C10.7924 9.8 11.6 8.9924 11.6 8C11.6 7.0076 10.7924 6.2 9.8 6.2ZM12.8 8.6V7.4H14V8.6H12.8ZM5 10.4C4.21903 10.4 3.55872 10.9032 3.31016 11.6H2V12.8H3.31016C3.55872 13.4967 4.21903 14 5 14C5.9924 14 6.8 13.1924 6.8 12.2C6.8 11.2076 5.9924 10.4 5 10.4ZM8 12.8V11.6H14V12.8H8Z"
      fill="#065CAB"
    />
  </svg>
</template>

<script>
export default {
  name: "MixIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
